import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  animate,
  animateChild,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NgIf } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { delay } from 'rxjs/operators';
import { PwaService } from './core/services/pwa.service';
import { SplashScreenComponent } from './shared/components/splash-screen/splash-screen.component';

@Component({
  standalone: true,
  selector: 'app-root',
  template: `
    <splash-screen *ngIf="show" @fadeOut></splash-screen>
    <router-outlet></router-outlet>
  `,
  imports: [RouterModule, SplashScreenComponent, NgIf],
  animations: [
    trigger('fadeOut', [
      transition(':leave', [
        query(':leave', animateChild(), { optional: true }),
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AppComponent {
  translate = inject(TranslateService);

  show = true;
  constructor(
    private pwaService: PwaService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'lgo-logo-black',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/logo-black.svg')
    );
    iconRegistry.addSvgIcon(
      'email',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/email-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'qr-code',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/qr-code.svg')
    );
    iconRegistry.addSvgIcon(
      'user-profile',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-profile.svg')
    );
    iconRegistry.addSvgIcon(
      'legal',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/legal.svg')
    );
    iconRegistry.addSvgIcon(
      'about',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/about.svg')
    );

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.translate.getBrowserLang() || 'en');
  }

  ngOnInit() {
    console.time('checkForUpdate');
    this.pwaService
      .checkForUpdate()
      .pipe(delay(250))
      .subscribe((result) => {
        this.show = result;
        console.timeEnd('checkForUpdate');
      });
    // setTimeout(() => (this.show = false), 3000);
  }
}
