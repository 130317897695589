import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class PWAAuthService {
  constructor(protected keycloak: KeycloakService) {}

  isAutenticated(): Promise<boolean> {
    return this.keycloak.isLoggedIn();
  }

  logout(redirectUrl?: string): Promise<void> {
    if (redirectUrl) {
      const url = window.location.origin + redirectUrl;
      return this.keycloak.logout(url);
    }
    return this.keycloak.logout();
  }
}
